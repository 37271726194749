import React from 'react'
import { Flex, Box } from 'rebass'
import { useIntl, FormattedMessage, Link } from 'gatsby-plugin-intl'

import Layout from '../components/Layout'
import {
  PhoneContainerDemo,
  ButtonClient,
} from '../components/header/Header.styled'

const Demo = props => {
  const intl = useIntl()
  return (
    <Layout {...props}>
      <Flex px={3} justifyContent="center" style={{ marginTop: 250 }}>
        <Box p={2} width={[1, 1 / 2]} style={{ maxWidth: '720px' }}>
          <div>
            <h2 style={{ textAlign: 'center' }}>
              {intl.formatMessage({ id: 'demo_title' })}
            </h2>
            <p style={{ textAlign: 'center' }}>
              Aquí podrás ver como se pueden apreciar tus menús, categorías,
              productos y modificadores.
            </p>
          </div>
          <PhoneContainerDemo>
            <iframe
              title="demo"
              height="568"
              width="320"
              style={{ border: '1px solid #ddd' }}
              src="https://www.2dine.io/d/r/4f20ed95-e56b-47d9-8a80-780c8509d4fb"
            ></iframe>
          </PhoneContainerDemo>

          <div style={{ marginTop: 32, textAlign: 'center' }}>
            <ButtonClient
              href="https://admin.2dine.io/login/signup"
              target="_blank"
            >
              <FormattedMessage id="demo_register_now" />
            </ButtonClient>
          </div>
        </Box>
      </Flex>
    </Layout>
  )
}

export default Demo
